import { ref } from 'vue'
import axios from 'axios'
export const useCancelAjax = (): any => {
  const cancelFnList = ref<any>([])
  const createCancelList = (): any => {
    return new axios.CancelToken(function executor (c) {
      cancelFnList.value.push(c)
    })
  }
  return {
    cancelFnList,
    createCancelList
  }
}
