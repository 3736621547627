
import { defineComponent, onMounted, computed } from 'vue'
import SVGA from 'svgaplayerweb'
import { useI18n } from 'vue-i18n'
import { getStoredLanguage, saveStoredLanguage } from '@/utils/languageStorage'

export default defineComponent({
  setup () {
    const { locale } = useI18n()
    const playerSvga = () => {
      const player2 = new SVGA.Player('#loginSvga')
      const parser2 = new SVGA.Parser()
      parser2.load('./newLogin.svga', function (videoItem) {
        player2.setVideoItem(videoItem)
        player2.startAnimation()
      })
    }

    const languageType = computed<string>(() => {
      return locale.value === 'zh-CN' ? 'EN' : '中'
    })

    const handleChangeLanguage = () => {
      if (getStoredLanguage() === 'en-US') {
        saveStoredLanguage('zh-CN')
      } else {
        saveStoredLanguage('en-US')
      }
      window.location.reload()
    }

    onMounted(() => {
      playerSvga()
    })

    return {
      languageType,
      handleChangeLanguage
    }
  }
})
